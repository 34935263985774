/* income.css */

.income {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 40px);
  background: #ffffff;
  color: #333;
  padding: 20px 20px 0 20px;
  font-family: "Montserrat", sans-serif;
}

.income-content {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 140px);
  overflow: hidden; /* This prevents the entire document content from scrolling */
  margin-top: 20px;
  box-shadow: var(--color-secondary) 0px 1px 4px,
    var(--color-secondary) 0px 0px 0px 3px;
  border-radius: 20px;
}

.search-box {
  flex-shrink: 0;
  overflow-x: auto;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 10;
  background-image: var(--color-gradient-secondary) !important;
  border-radius: 20px;
  margin-bottom: 7px;
  scrollbar-width: none;
}

.search-box-content {
  display: inline-block;
  padding-right: 7px; /* Adds padding on the right */
  min-width: 100%; /* Ensures it spans the full width of the parent */
}

.table-container {
  flex-grow: 1;
  overflow: auto; /* Allows for both vertical and horizontal scrolling */
  position: relative;
}

.income-table {
  width: 100%;
  border-collapse: collapse;
  background: #ffffff;
}

.income-table thead {
  background-image: var(--color-gradient-secondary) !important;
  z-index: 9999 !important;
  color: #fff;
}

.income-table thead th {
  position: sticky;
  top: 0;
  color: #fff;
  z-index: 2;
  white-space: nowrap;
}

.income-table th,
.income-table td {
  padding: 12px 10px;
  border-bottom: 1px solid #e0e0e0;
}

.income-table tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

.income-table tbody tr:hover {
  background: #e6e6e6;
}

.no-data-found,
.no-data-message {
  text-align: center;
  padding: 50px 20px;
  background: var(--color-gradient-primary);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  font-size: 25px;
}

.add-income-btn,
.prev-btn,
.next-btn {
  padding: 10px 20px;
  background: var(--color-gradient-secondary);
  color: white;
  white-space: nowrap;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-income-btn:hover,
.prev-btn:hover,
.next-btn:hover {
  background: var(--color-secondary);
}

.disabled {
  background: var(--color-light-secondary) !important;
}

.income-footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
