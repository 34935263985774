.employment-Management {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 40px);
    background: #ffffff;
    color: hsl(0, 0%, 20%);
    padding: 35px 20px 0 20px;
    font-family: "Montserrat", sans-serif;
}

.employment-management-content{
    color: black;
    font-size: 30px;
}