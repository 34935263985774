.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--color-gradient-secondary);
  width: 100%;
  position: relative;
}

.logo-main {
  position: absolute;
  width: 120px;
  top: 20px;
  left: 50px;
  cursor: pointer;
}

.login-box {
  display: flex;
}
.login_text {
  max-width: 450px;
}

.login_header {
  font-size: 60px;
  text-align: left;
  color: #fff;
}

.login-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
  border: 2px solid var(--color-secondary);
  margin-left: 200px;
}

.login-form h2 {
  margin-bottom: 3.5rem;
  color: var(--color-primary);
}

.form-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color-primary);
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--color-grey);
  border-radius: 4px;
}

.password-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.password-wrapper input {
  flex: 1;
  padding-right: 2.5rem;
}

.eye-button {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--color-black);
}

.eye-button:focus {
  outline: none;
}

.eye-button:hover {
  color: var(--color-medium-secondary);
}

.login-button {
  background: var(--color-gradient-secondary);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  width: 100%;
}

.login-button:hover {
  background: var(--color-medium-secondary);
}

.error {
  color: red;
  margin-bottom: 1rem;
}

.login-button:hover {
  background: var(--color-medium-secondary);
}

.error {
  color: red;
  margin-bottom: 1rem;
}
