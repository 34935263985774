.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-container {
  display: flex;
  width: 90%;
  height: 90%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-image {
  position: relative;
  width: 300px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
}

.crop-img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;

  opacity: 0;
  transition: 0.5s ease;
}

.modal-image:hover .overlay {
  opacity: 1;
}

.overlay-image {
  height: 100%;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  object-fit: cover;
}

.modal-content {
  width: 100%;
  padding: 20px;
}

.modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-heading {
  font-size: 30px;
  margin-top: 0;
  border-bottom: 4px solid var(--color-primary) !important;
}

.modal-close {
  cursor: pointer;
  margin-top: 0;
  font-size: 2.5rem;
  color: var(--color-primary) !important;
}

.modal-form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0 40px;
}

.input-box {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 10px;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="date"],
input[type="email"],
textarea {
  width: 100%;
  height: 38px;
  padding: 4px 7px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.show-btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}

.button-common {
  padding: 10px 20px;
  background: var(--color-gradient-secondary) !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  font-size: 1rem;
  margin-top: 20px;
  width: 200px;
}

.button-common:hover {
  background: var(--color-secondary) !important;
}

.submit-btn {
  padding: 10px 20px;
  background: var(--color-gradient-secondary) !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block; /* Makes the button block level, so it fills the width */
  width: 100%;
  font-size: 1rem;
  margin-top: 20px;
}

.submit-btn:hover {
  background: var(--color-secondary) !important;
}

.delete-btn {
  background: var(--color-gradient-primary) !important;
}
.delete-btn:hover {
  background: var(--color-primary) !important;
}
