.logout-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.logout-modal {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 400px; /* Set the width of the modal */
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal-content {
  display: flex;
  flex-direction: column;
}
.modal-content p {
  font-weight: bold;
  font-size: 18px;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.button-yes {
  margin-right: 10px;
  padding: 8px 26px;
  border: none;
  border-radius: 4px;
  background-color: #e33838;
  color: white;
  cursor: pointer;
  font-weight: bold;
}
.button-yes:hover {
  background-color: #f10909;
}
.button-no {
  margin-right: 10px;
  padding: 8px 26px;
  border: none;
  border-radius: 4px;
  background-color: #5e50a1;
  color: white;
  cursor: pointer;
  font-weight: bold;
}
.button-no:hover {
  background-color: #6244fae8;
}
