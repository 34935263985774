.expense-Management {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 40px);
  background: #ffffff;
  color: hsl(0, 0%, 20%);
  padding: 35px 20px 0 20px;
  font-family: "Montserrat", sans-serif;
}

.expense-management-content {
  color: black;
  font-size: 30px;
  overflow-y: auto;
  width: 100%;
}

.expense-management-content > h2 {
  font-size: 25px;
  color: var(--color-primary);
  margin-bottom: 20px;
}

.expense-management-details {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 20px;
}

.expense-management-fields {
  width: 350px;
  margin-bottom: 30px;
}

.strong {
  font-size: 23px;
  margin-bottom: 20px;
  font-weight: bold;
}

.expense-values {
  font-size: 21px;
}

.input-with-remove {
  display: flex;
  align-items: center;
}

.input-with-remove input {
  margin-right: 8px;
}

.remove-btn {
  background: var(--color-primary);
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bolder;
  border-radius: 4px;
  height: 30px;
  width: 30px;
}

.remove-btn:hover {
  color: var(--color-primary);
  background-color: #fff;
}

.add-btn-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.add-btn {
  margin-top: 10px;
  width: 30%;
  padding: 7px 20px;
  font-size: 15px;
  font-weight: 600;
  background: var(--color-gradient-secondary);
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-btn:hover {
  color: var(--color-secondary);
  background-color: #fff;
}

.button-div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.edit-btn {
  width: 30%;
  padding: 10px 30px;
  font-size: 17px;
  font-weight: 600;
  background: var(--color-gradient-secondary);
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 30px;
}

.edit-btn:hover {
  color: var(--color-secondary);
  background-color: #fff;
}
