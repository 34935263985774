.logout_modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout_modal {
  background: white;
  padding: 30px 50px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logout_modal h2 {
  color: var(--color-primary);
}

.logout_modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirm_button {
  background: var(--color-gradient-primary);
  color: white;
  padding: 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel_button {
  background: var(--color-gradient-secondary);
  color: white;
  padding: 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
