.carousel {
  width: 100%;
  margin: auto;
  position: relative;
}

.image-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.image-container img {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  z-index: 2;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 50px;
  color: #599f4d; /* Adjust color as needed */
}

.slick-dots {
  bottom: 20px;
}

/* Media query for tablets (portrait and landscape) */
@media (max-width: 1024px) {
  .image-container {
    height: 300px; /* Adjust height for tablets */
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
  }

  .slick-dots {
    bottom: 15px;
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .image-container {
    height: 200px; /* Adjust height for mobile devices */
  }

  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
  }

  .slick-dots {
    bottom: 10px;
  }
}
