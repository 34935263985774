.setting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.setting_pic {
  width: 300px;
  height: 300px;
}

.setting_text {
  color: var(--color-primary);
}
