:root {
  --color-secondary: #18563a;
  --color-medium-secondary: #599f4d;
  --color-light-secondary: #cbdac3;
  --color-yellow: #edd056;
  --color-gradient-yellow: linear-gradient(to bottom right, #edcf56, #f4e6b1);
  --color-gradient-secondary: linear-gradient(
    to bottom right,
    #18563a,
    #599f4d
  );
  --color-black: #1e1e20;
  --color-grey: #e0e0e0;
  --color-primary: #6c3512;
  --color-medium-primary: #6c3512;
  --color-gradient-primary: linear-gradient(to bottom right, #6c3512, #2a1405);
}

/* Main App container */
.app {
  display: flex;
  height: 100vh;
}

/* Page content */
.page-content {
  display: flex;
  height: calc(100vh - 30px);
  transition: width 0.3s;
  padding: 30px 0 0 0;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
}

/* When sidebar is expanded */
.page-content.expanded {
  width: calc(100% - 250px);
}

/* When sidebar is collapsed */
.page-content.collapsed {
  width: calc(100% - 75px);
}

/* Full-width mode for non-authenticated state */
.full-width {
  width: 100%;
  padding: 0;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #cbdac3;
}

::-webkit-scrollbar-thumb {
  background: #18563a;
}

::-webkit-scrollbar-thumb:hover {
  background: #599f4d;
}

/* Media query for collapsed sidebar */
@media (max-width: 700px) {
  .sidebar {
    width: 75px;
  }

  .page-content.expanded {
    width: calc(100% - 75px);
  }
}
