/* index.css or App.css */

/* Import Montserrat font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

/* Apply Montserrat font to the entire application */
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}
