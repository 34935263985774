/* Dashboard.css */

.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  color: #333;
  padding: 10px;
  font-family: "Montserrat", sans-serif;
  overflow-y: scroll;
}

.dash_board {
  padding-top: 40px;
  border-left: 10px solid var(--color-secondary);
  height: 100vh;
}

.dashboard-content {
  max-height: calc(100vh - 120px);
  max-width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.bar-charts,
.Composed-charts {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.bar-chart,
.bar-chart-stacked,
.Composed-chart1,
.Composed-chart2 {
  display: flex;
  flex-direction: column;
  width: 50%;
}
