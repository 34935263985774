.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 50px;
  justify-content: center;
}

.container {
  background: white;
  width: 95%;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-x: scroll;
}

.container h4 {
  text-align: center;
  font-size: 25px;
}

h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.table-responsive {
  width: 100%;
  overflow-x: scroll;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table,
th,
td {
  border: 1px solid black;
}

th,
td {
  padding: 10px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

.completed-100 {
  background-color: #d4edda;
}

.completed-70 {
  background-color: #f8d7da;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 10px;
  }

  .container h4,
  h1 {
    font-size: 20px;
  }

  th,
  td {
    padding: 5px;
    font-size: 14px;
  }

  .main-container {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
