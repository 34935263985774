.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-container {
  display: flex;
  width: 95%;
  height: 90%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-map-poly {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 100%;
  gap: 15px;
  overflow: hidden;
}

.modal-map {
  width: 100%;
  min-height: 48%;
  background-color: #3617d1;
}
.modal-poly {
  width: 100%;
  background-color: #6c5db4;
  min-height: 48%;
}

.modal-content {
  width: 100%;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.swiper {
  width: 100%;
  min-height: 400px;
  max-height: 400px;
  margin-bottom: 40px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-heading {
  font-size: 30px;
  margin-top: 0;
  border-bottom: 4px solid var(--color-primary) !important;
}

.modal-close {
  cursor: pointer;
  margin-top: 0;
  font-size: 2.5rem;
  color: var(--color-primary) !important;
}

.modal-form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0 40px;
}

.select {
  width: 100%;
  height: 38px;
  padding: 4px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.options {
  width: 100%;
  height: 33px;
}

.input-box {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 10px;
  margin-bottom: 10px;
}

.form-label {
  color: var(--color-primary) !important;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="date"],
input[type="email"],
textarea {
  width: 100%;
  height: 38px;
  padding: 4px 7px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.show-btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}

.button-common {
  padding: 10px 20px;
  background: var(--color-gradient-secondary) !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  font-size: 1rem;
  margin-top: 20px;
  width: 200px;
}

.button-common:hover {
  background: var(--color-secondary) !important;
}

.submit-btn {
  padding: 10px 20px;
  background: var(--color-gradient-secondary) !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block; /* Makes the button block level, so it fills the width */
  width: 100%;
  font-size: 1rem;
  margin-top: 20px;
}

.submit-btn:hover {
  background: var(--color-secondary) !important;
}

.delete-btn {
  background: var(--color-gradient-primary) !important;
}
.delete-btn:hover {
  background: var(--color-primary) !important;
}
