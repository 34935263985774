.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
  height: 65px;
  color: #fff;
  background-image: var(--color-gradient-secondary);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.web-link-btn {
  display: flex;
  justify-content: flex-end;
}

.header-content,
.header-content-two {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: flex-end;
}

.user-name {
  margin-right: 20px;
  font-weight: bold;
}

.button {
  font-weight: bold;
  padding: 9px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-btn {
  background: var(--color-gradient-secondary); /* Green for login */
}

.logout-btn {
  background: var(--color-gradient-primary);
}

.button svg {
  margin-right: 5px;
}
