.search-container {
  padding: 5px 10px;
  background-image: var(--color-gradient-secondary) !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  overscroll-behavior: contain;
}

.search-filters {
  display: flex;
  width: 100%;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

.upper-filter,
.lower-filter {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: space-between;
}

.search-container .filter-control {
  height: 25px;
  min-width: 150px;
  max-width: 150px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  vertical-align: middle;
  border-radius: 4px;
  background-color: white;
  color: #333;
  font-size: 12px;
}

.search-container .search-filters input[type="text"],
.search-container .search-filters input[type="number"],
.search-container .search-filters input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-container select.filter-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 24px;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-position: right 8px center;
  background-repeat: no-repeat;
  background-size: 12px;
  margin-bottom: 9px;
}

.search-btn,
.reset-btn {
  padding: 5px 10px;
  font-size: 12px;
  min-width: 135px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  margin-bottom: 9px;
}

.search-btn {
  background-image: var(--color-gradient-secondary) !important;
  color: #ddd;
}
.search-btn:hover {
  background-image: var(--color-gradient-secondary) !important;
  color: #ddd;
}

.reset-btn {
  color: #ddd;
  background-image: var(--color-gradient-primary);
}
.reset-btn:hover {
  color: #ddd;
  background-color: rgba(234, 31, 31, 0.908);
}
