@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

.sidebar {
  width: 250px;
  background-color: #fff;
  color: #333;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  z-index: 99;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: width 0.3s;
}

.side_bar {
  width: 270px;
}

.sidebar.collapsed {
  width: 75px;
}

.sidebar-header,
.sidebar-menu,
.sidebar-footer {
  padding: 0 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  background-image: var(--color-gradient-secondary);
  color: #fff;
  position: sticky;
  top: 0;
}

.logo-section {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: #fff;
  padding: 8px 0;
  cursor: pointer;
}

.logo-icon {
  width: 33px;
  margin-right: 10px;
}

.toggle-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.4rem;
  padding: 10px 0;
  cursor: pointer;
}

.sidebar-header h4 {
  font-weight: bold;
  color: #fff;
  line-height: 6px;
  white-space: nowrap;
}

.search-bar {
  position: relative;
  padding: 0 20px;
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px 15px 10px 40px;
  border-radius: 20px;
  border: 1px solid #e4e4e4;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 35px;
  transform: translateY(-50%);
  color: var(--color-secondary);
  font-size: 1em;
}

.menu-section h2 {
  color: #aaa;
  font-size: 0.85em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.menu-item {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding: 12px 20px;
  margin-bottom: 7px;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s, transform 0.3s;
}

.menu-item:hover {
  background-color: #f9f9f9;
}

.menu-item.active .icon {
  background-color: var(--color-secondary);
  color: #fff;
  padding: 15px;
  border-radius: 50%;
  transform: scale(1.1); /* Make the icon larger */
  transition: all 0.5s ease; /* Smooth transition */
}

.menu-item.active {
  color: var(--color-secondary);
}

.menu-item .icon {
  min-width: 20px;
  margin-right: 20px;
  text-align: center;
  font-size: 1.2rem;
}

.sidebar-footer {
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
}

.footer-icon {
  font-size: 1.5rem;
  color: #5e50a1;
}

.sidebar.collapsed .menu-item span,
.sidebar.collapsed .sidebar-header h4,
.sidebar.collapsed .logo-icon {
  display: none;
}

.sidebar.collapsed .menu-item {
  justify-content: center;
}

.sidebar.collapsed .toggle-button {
  display: block;
}

/* Show sidebar menu name on hover in collapsed state */
.sidebar.collapsed .menu-item {
  position: relative;
}

.sidebar.collapsed .menu-item::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 1;
}

.sidebar.collapsed .menu-item:hover::after {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 700px) {
  .sidebar {
    width: 75px;
  }

  .sidebar-header {
    padding: 0 10px;
  }

  .sidebar-menu,
  .sidebar-footer {
    padding: 0 10px;
  }

  .menu-item span,
  .sidebar-header h4,
  .logo-icon {
    display: none;
  }

  .menu-item {
    justify-content: center;
  }
}
